<template>
  <client-only>
    <div class="flex justify-between flex-col min-h-screen">
      <div class="flex justify-center my-5">
        <div>
          <NuxtImg data-not-lazy :src="fileUrl(navigatieData.logo)" alt="Vers van de Velde" class="object-scale-down h-16"/>
        </div>
      </div>

      <div class="flex-1">
        <slot/>
      </div>

      <div class="bg-primary-600 w-full h-4/6 py-8 mx-auto">
        <div class="mx-auto grid grid-cols-1 md:grid-cols-4 lg:grid-cols-10 md:w-8/12 text-xs text-white">

          <div class="col-span-1 md:col-span-2 lg:col-span-3 text-center mt-2 sm:text-center md:mt-auto md:mb-auto">
            <p>©{{ new Date().getFullYear() }} <a class="hover:text-webscoped auto-transit" href="https://webscoped.nl"
                                                  target="_blank">Webscoped</a> - Alle rechten voorbehouden</p>
          </div>
          <div class="col-span-1 md:col-span-2 lg:col-span-2 text-center mt-2 md:mt-auto md:mb-auto">
            <NuxtLink to="/algemenevoorwaarden" target="_blank"><p>Algemene voorwaarden</p></NuxtLink>
          </div>
          <div class="col-span-1 md:col-span-2 lg:col-span-2 text-center mt-2 md:mt-auto md:mb-auto">
            <NuxtLink to="/algemenevoorwaarden" target="_blank"><p>Privacy voorwaarden</p></NuxtLink>
          </div>
          <div class="col-span-1 md:col-span-2 lg:col-span-2 text-center mt-2 md:mt-auto md:mb-auto">
            <a href="mailto:info@versvandevelde.nl">info@versvandevelde.nl</a>
          </div>
          <div
              class="col-span-1 md:col-span-4 lg:col-span-1 text-center mt-4 md:mt-auto md:mb-auto flex justify-center">
            <a href="https://www.facebook.com/vers.vandevelde/" target="_blank">
              <div class="rounded-full w-9 h-9 bg-white text-primary-600 grid">
                <font-awesome-icon class="text-xl place-self-center" :icon="faFacebookF"/>
              </div>
            </a>
            <a href="https://www.instagram.com/vers.vandevelde/" target="_blank">
              <div class="rounded-full w-9 h-9 bg-white text-primary-600 grid ml-2">
                <font-awesome-icon class="text-xl place-self-center" :icon="faInstagram"/>
              </div>
            </a>
          </div>
        </div>
      </div>

    </div>
  </client-only>
</template>

<script setup>
import {faFacebookF, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const {getItems} = useDirectusItems();
const {fileUrl} = useFiles()

const navigatieData = await getItems({
  collection: "header"
});
</script>